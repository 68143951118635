import React, { useState } from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import styles from "src/styles/components/navbar.module.scss"
import siteInfo from "content/data/info.json"
import cx from "classnames"
import Dropdown from "src/components/dropdown"
import {
  Collapse,
  List,
  ListItem,
  ListItemText,
  SwipeableDrawer,
} from "@material-ui/core"
import MenuIcon from "@material-ui/icons/Menu"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  mainMenuText: {
    fontSize: "18px",
    fontFamily: "Runda",
  },
  subMenuText: {
    fontSize: "18px",
    fontFamily: "Runda",
    paddingLeft: theme.spacing(4),
  },
}))

export default function Navbar({ logo, className }) {
  const classes = useStyles()
  const [drawerOpen, setDrawerOpen] = useState(false)
  return (
    <nav className={cx(styles.barRoot, className)}>
      <div
        className={cx(styles.burgerButtonContainer)}
        onClick={() => setDrawerOpen(true)}
      >
        <MenuIcon />
      </div>
      <div className={styles.logoContainer}>
        <a href="/">
          <img alt="logo" className={styles.logo} src={logo || siteInfo.logo} />
        </a>
      </div>
      <SwipeableDrawer
        anchor="left"
        onOpen={() => {}}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <div role="presentation" onKeyDown={() => setDrawerOpen(false)}>
          <List>
            {_.map(siteInfo.navlinks, ({ title, items, url }) => {
              if (items) {
                return (
                  <>
                    <ListItem key={title}>
                      <ListItemText
                        primary={title}
                        classes={{
                          primary: classes.mainMenuText,
                        }}
                      />
                    </ListItem>
                    <Collapse in={true} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {_.map(items, ({ title, url }) => {
                          return (
                            <ListItem
                              button
                              key={title}
                              component="a"
                              href={url}
                            >
                              <ListItemText
                                primary={title}
                                classes={{
                                  primary: classes.subMenuText,
                                }}
                              />
                            </ListItem>
                          )
                        })}
                      </List>
                    </Collapse>
                  </>
                )
              } else {
                return (
                  <ListItem button key={title} component="a" href={url}>
                    <ListItemText
                      primary={title}
                      classes={{
                        primary: classes.mainMenuText,
                      }}
                    />
                  </ListItem>
                )
              }
            })}

            <ListItem
              button
              key={"login"}
              component="a"
              href={siteInfo.login.url}
            >
              <ListItemText
                primary={siteInfo.login.text}
                classes={{
                  primary: classes.mainMenuText,
                }}
              />
            </ListItem>
          </List>
        </div>
      </SwipeableDrawer>
      <div className={cx(styles.linksContainer, "noselect")}>
        {_.map(siteInfo.navlinks, ({ title, items, url }) => {
          if (items) {
            return (
              <Dropdown
                anchor={<span className={styles.anchorLink}>{title}</span>}
                options={_.map(items, ({ title, url }) => {
                  return (
                    <a key={title} href={url} className={styles.anchorLink}>
                      {title}
                    </a>
                  )
                })}
              />
            )
          }

          return (
            <div className={styles.navLink} key={title}>
              <a className={styles.anchorLink} href={url}>
                {title}
              </a>
            </div>
          )
        })}
        {siteInfo.login.url && (
          <div className={styles.navLink}>
            {
              <a href={siteInfo.login.url}>
                <button className={styles.buttonLink}>
                  {siteInfo.login.text}
                </button>
              </a>
            }
          </div>
        )}
      </div>
    </nav>
  )
}

Navbar.propTypes = {
  logo: PropTypes.string,
  className: PropTypes.string,
}
