import React, { useCallback, useState } from "react"
import PropTypes from "prop-types"
import styles from "src/styles/components/dropdown.module.scss"
import cx from "classnames"
import { useEffect } from "react"

const Dropdown = ({ anchor, options }) => {
  const [open, setOpen] = useState(false)

  const handleClick = useCallback(() => {
    setOpen(false)
  }, [])

  const handleEscPress = useCallback(e => {
    if (e.key === "Escape") {
      setOpen(false)
    }
  }, [])

  useEffect(() => {
    if (open) {
      document.addEventListener("click", handleClick)
      return () => document.removeEventListener("click", handleClick)
    }
  }, [open])

  useEffect(() => {
    document.addEventListener("keydown", handleEscPress)
    return () => document.removeEventListener("keydown", handleEscPress)
  }, [])

  return (
    <div className={styles.root}>
      <div
        className={cx(styles.anchor, { [styles.anchorOpen]: open })}
        onClick={() => setOpen(v => !v)}
      >
        <div
          className={cx(styles.anchorBackground, {
            [styles.anchorBackgroundOpen]: open,
          })}
        />
        {anchor}
      </div>
      <div className={cx(styles.popper, { [styles.popperOpen]: open })}>
        {options}
      </div>
    </div>
  )
}

Dropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.node).isRequired,
  anchor: PropTypes.node.isRequired,
}

Dropdown.defaultProps = {}

export default Dropdown
